import {isMobile} from 'react-device-detect';
import {addTimeout} from './timeManager';
import {isBrowser} from './articleUtil';

const definedSlots = {};
let isOnRenderSet = false;
let previousPageCalledForAds = '';

const setTargeting = (targetingArray) => {
  targetingArray && targetingArray.forEach(targeting => {
    window.googletag.pubads()
      .setTargeting(targeting.key, targeting.value);
  });
  if (process.env.GATSBY_DEPLOYMENT_ENV && process.env.GATSBY_DEPLOYMENT_ENV !== '') {
    window.googletag.pubads()
      .setTargeting('env', process.env.GATSBY_DEPLOYMENT_ENV);
  }
};

const clearTargeting = (targetingArray) => {
  targetingArray && targetingArray.forEach(targeting => {
    window.googletag.pubads()
      .clearTargeting(targeting);
  });
};

const refreshAds = (page) => {
  try {
    let slots = [];
    if (definedSlots['bottom_anchor']) {
      slots.push(definedSlots['bottom_anchor']);
    }
    if (definedSlots['oneByOneAdUnit']) {
      slots.push(definedSlots['oneByOneAdUnit']);
    }

    if (definedSlots[page]) {
      slots = slots.concat(definedSlots[page]);
    }

    switch (page) {
      case 'article_page':
        if (definedSlots['pt_article_rightcol_desktop']) {
          slots.push(definedSlots['pt_article_rightcol_desktop']);
        }
        try {
          stpd.refreshAdUnit('pt_article_endcontent');
        } catch (e) {
          console.log(e);
        }
        break;
      case 'category_page':
      case 'video_category_page':
      case 'subcategory_page':
      case 'video_article_page':
        if (definedSlots['pt_rightcol_desktop']) {
          slots.push(definedSlots['pt_rightcol_desktop']);
        }
        break;

    }

    window.googletag.pubads()
      .refresh(slots, {changeCorrelator: true});
  } catch (e) {
    console.log(e);
  }
};

const addPreBidScript = () => {
  if (isBrowser()) {
    const headElement = document.getElementsByTagName('head')[0];
    const prebidScript = document.createElement('script');
    prebidScript.src = `https://stpd.cloud/saas/6857`;
    prebidScript.async = true;
    prebidScript.defer = true;
    headElement.appendChild(prebidScript);
  }
};

const setOnRender = () => {
  !isOnRenderSet && window.googletag.pubads()
    .addEventListener('slotRenderEnded', onRender);
  isOnRenderSet = true;
};

const onRender = (event) => {
  const slot = event.slot;
  console.log('slot id: ', slot.getSlotElementId());
  console.log('isEmpty: ', event.isEmpty);
  console.log('=============================');

  const isEmpty = event.isEmpty;

  const element = document.getElementById(slot.getSlotElementId());
  if (element) {
    const parentContainer = element.parentNode;
    const children = parentContainer.childNodes;
    let adTextNode;
    /*let isEmpty;*/
    for (let i = 0; i < children.length; i++) {
      if (children[i].className === 'advertisement-text') {
        adTextNode = children[i];
        break;
      }
    }
    if (!adTextNode) {
      return;
    }
    /*isEmpty = !(element.children && element.children[0] && element.children[0].childElementCount);*/
    console.log('slot id: ', slot.getSlotElementId());
    console.log('isEmpty: ', isEmpty);
    console.log('----------------------------');
    if (isEmpty) {
      adTextNode && (adTextNode.style.opacity = 0);
      parentContainer.style.display = 'none';
    } else {
      adTextNode && (adTextNode.style.opacity = 1);
      parentContainer.style.display = 'block';
    }
  }
};

export const bottomAnchor = () => {
  if (definedSlots['bottom_anchor']) {
    return;
  }
  window.googletag = window.googletag || {cmd: []};
  isMobile && window.googletag.cmd.push(() => {
    const anchorSlot = window.googletag.defineOutOfPageSlot(
      '/22218985137/pensiontimes/pt_sticky',
      window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
    );
    if (anchorSlot) {
      anchorSlot.addService(window.googletag.pubads());
      definedSlots['bottom_anchor'] = anchorSlot;
    } else {
      console.log('Anchor Slot Not available');
    }
  });
};

export const destroyPreviousAdSlots = () => {
  if (previousPageCalledForAds === '') {
    return;
  }
  try {
    window.googletag && window.googletag.destroySlots(definedSlots[previousPageCalledForAds]);
  } catch (e) {
    console.log(e);
  }

  definedSlots[previousPageCalledForAds] = null;
};

export const destroyLongerAdSlots = () => {
  definedSlots['longerAds'] && window.googletag && window.googletag.destroySlots(definedSlots['longerAds']);
  definedSlots['longerAds'] = null;
};

export const rightSide = (divId) => {
  window.googletag = window.googletag || {cmd: []};
  let rightSideSlot;
  if (!isMobile && !definedSlots[divId]) {
    window.googletag.cmd.push(() => {
      if (!definedSlots[divId]) {
        rightSideSlot = window.googletag
          .defineSlot(
            '/22218985137/pensiontimes/pt_rightcol_desktop',
            [[300, 250], [300, 600], 'fluid'],
            divId
          );
        if (rightSideSlot) {
          rightSideSlot.addService(window.googletag.pubads());
          definedSlots[divId] = rightSideSlot;
        }
      }
    });
  }
};

export const oneByOneAdUnit = () => {
  window.googletag = window.googletag || {cmd: []};

  if (!definedSlots['oneByOneAdUnit']) {
    window.googletag.cmd.push(() => {
      const adSlot = window.googletag.defineSlot(
        `/22218985137/pensiontimes/special`,
        [1, 1],
        'special'
      );

      if (adSlot) {
        adSlot.addService(window.googletag.pubads());
        definedSlots['oneByOneAdUnit'] = adSlot;
      }
    });
  }
};

export const homePageAds = () => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'home_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'home_page';

  bottomAnchor();
  oneByOneAdUnit();

  if (definedSlots['home_page']) {
    return addTimeout(refreshAds, 50, 'home_page');
  }
  window.googletag.cmd.push(() => {
    let slot1, slot2, slot3;
    if (isMobile) {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_mobile',
          [[320, 50], [300, 100], 'fluid'],
          'pt_incontent1'
        );
      slot2 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent2_mobile',
          [[320, 50], [300, 100], 'fluid'],
          'pt_incontent2'
        );
      /*slot3 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_endcontent_mobile',
          [[320, 50], [300, 100], 'fluid'],
          'pt_endcontent'
        )*/
    } else {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_incontent1'
        );
      slot2 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent2_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_incontent2'
        );
      /*slot3 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_endcontent_desktop',
          [[728, 90], [970, 250], 'fluid'],
          'pt_endcontent'
        )*/
    }
    definedSlots['home_page'] = [];

    slot1 && slot1.addService(window.googletag.pubads()) && definedSlots['home_page'].push(slot1);
    slot2 && slot2.addService(window.googletag.pubads()) && definedSlots['home_page'].push(slot2);
    slot3 && slot3.addService(window.googletag.pubads()) && definedSlots['home_page'].push(slot3);

    window.googletag.pubads()
      .enableSingleRequest();
    window.googletag.pubads()
      .collapseEmptyDivs();
    window.googletag.pubads()
      .setCentering(true);
    window.googletag.pubads()
      .enableLazyLoad();
    setTargeting([{key: 'page', value: 'homepage'}]);
    clearTargeting(['category', 'subcategory', 'slug']);
    setOnRender();
    window.googletag.enableServices();

    window.googletag.display('pt_incontent1');
    window.googletag.display('pt_incontent2');
    //window.googletag.display('pt_endcontent');
  });
};

export const articlePageAds = (targetingArray, longAdCount, showMiddleAds = true) => {
  console.log("articlePageAds called");
  console.log(window.googletag);
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'article_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'article_page';

  bottomAnchor();
  rightSide('pt_article_rightcol_desktop');
  oneByOneAdUnit();

  if (longAdCount) {
    window.googletag.cmd.push(() => {
      destroyLongerAdSlots();
      for (let i = 1; i <= longAdCount; i++) {
        let slot;
        if (isMobile) {
          slot = window.googletag
            .defineSlot(
              `/22218985137/pensiontimes/pt_incontent${i + 3}_mobile`,
              [[300, 250], [336, 280], [300, 600], 'fluid'],
              `pt_article_incontent-0${i}`
            )
            .addService(window.googletag.pubads());
        } else {
          slot = window.googletag
            .defineSlot(
              `/22218985137/pensiontimes/pt_incontent${i + 3}_desktop`,
              ['fluid', [970, 250], [728, 90]],
              `pt_article_incontent-0${i}`
            )
            .addService(window.googletag.pubads());
        }
        if (slot) {
          definedSlots['longerAds'] = definedSlots['longerAds'] || [];
          definedSlots['longerAds'].push(slot);
        }
      }
    });
  }

  if (definedSlots['article_page']) {
    setTargeting(targetingArray);
    console.log("articlePageAds slots already defined");
    return addTimeout(() => {
      refreshAds('article_page');
      for (let i = 1; i <= longAdCount; i++) {
        window.googletag.display(`pt_article_incontent-0${i}`);
      }
    }, 50);
  }
  console.log("articlePageAds slots not defined");
  window.googletag.cmd.push(() => {
    let slot1, slot2, slot3, slot4;
    if (isMobile) {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'pt_article_incontent1'
        )
        .addService(window.googletag.pubads());
      slot2 = showMiddleAds && window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent2_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'pt_article_incontent2'
        )
        .addService(window.googletag.pubads());
      slot3 = showMiddleAds && window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent3_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'pt_article_incontent3'
        )
        .addService(window.googletag.pubads());
      slot4 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_endcontent_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'pt_article_endcontent'
        )
        .addService(window.googletag.pubads());
    } else {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_article_incontent1'
        )
        .addService(window.googletag.pubads());
      slot2 = showMiddleAds && window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent2_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_article_incontent2'
        )
        .addService(window.googletag.pubads());
      slot3 = showMiddleAds && window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent3_desktop',
          ['fluid', [970, 250], [728, 90]],
          'pt_article_incontent3'
        )
        .addService(window.googletag.pubads());
      slot4 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_endcontent_desktop',
          [[728, 90], [970, 250], 'fluid'],
          'pt_article_endcontent'
        )
        .addService(window.googletag.pubads());
    }

    setTargeting(targetingArray);
    window.googletag.pubads()
      .enableSingleRequest();
    window.googletag.pubads()
      .collapseEmptyDivs();
    window.googletag.pubads()
      .setCentering(true);
    window.googletag.pubads()
      .enableLazyLoad();
    setOnRender();
    window.googletag.pubads()
      .disableInitialLoad();
    window.googletag.enableServices();

    addPreBidScript();

    definedSlots['article_page'] = [];
    slot1 && definedSlots['article_page'].push(slot1);
    slot2 && definedSlots['article_page'].push(slot2);
    slot3 && definedSlots['article_page'].push(slot3);
    slot4 && definedSlots['article_page'].push(slot4);

    window.googletag.display('pt_article_incontent1');
    showMiddleAds && window.googletag.display('pt_article_incontent2');
    showMiddleAds && window.googletag.display('pt_article_incontent3');
    window.googletag.display('pt_article_endcontent');
    window.googletag.display('pt_article_rightcol_desktop');

    for (let i = 1; i <= longAdCount; i++) {
      window.googletag.display(`pt_article_incontent-0${i}`);
    }
  });
};

export const videoArticleAds = (targetingArray) => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'video_article_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'video_article_page';

  bottomAnchor();
  rightSide('pt_article_rightcol_desktop');
  oneByOneAdUnit();

  if (definedSlots['video_article_page']) {
    setTargeting(targetingArray);
    return addTimeout(refreshAds, 50, 'video_article_page');
  }

  let slot1;
  window.googletag.cmd.push(() => {
    if (isMobile) {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'pt_video_article_incontent1'
        )
        .addService(window.googletag.pubads());
    } else {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_video_article_incontent1'
        )
        .addService(window.googletag.pubads());
    }

    setTargeting(targetingArray);
    window.googletag.pubads()
      .enableSingleRequest();
    window.googletag.pubads()
      .collapseEmptyDivs();
    window.googletag.pubads()
      .setCentering(true);
    window.googletag.pubads()
      .enableLazyLoad();
    setOnRender();
    window.googletag.enableServices();
    definedSlots['video_article_page'] = [];
    definedSlots['video_article_page'].push(slot1);

    window.googletag.display('pt_video_article_incontent1');
    window.googletag.display('pt_article_rightcol_desktop');
  });
};

export const categoryPageAds = (targetingArray) => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'category_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'category_page';

  bottomAnchor();
  oneByOneAdUnit();

  if (definedSlots['category_page']) {
    setTargeting(targetingArray);
    return addTimeout(refreshAds, 50, 'category_page');
  }

  let slot1, slot2;
  window.googletag.cmd.push(() => {
    if (isMobile) {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'pt_category_incontent1'
        )
        .addService(window.googletag.pubads());
      slot2 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent2_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'pt_category_incontent2'
        )
        .addService(window.googletag.pubads());
      /*slot3 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent3_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'pt_category_incontent3'
        )
        .addService(window.googletag.pubads());
      slot4 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_endcontent_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'pt_category_endcontent'
        )
        .addService(window.googletag.pubads());*/
    } else {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_category_incontent1'
        )
        .addService(window.googletag.pubads());
      slot2 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent2_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_category_incontent2'
        )
        .addService(window.googletag.pubads());
      /*slot3 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent3_desktop',
          ['fluid', [970, 250], [728, 90]],
          'pt_category_incontent3'
        )
        .addService(window.googletag.pubads());
      slot4 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_endcontent_desktop',
          [[728, 90], [970, 250], 'fluid'],
          'pt_category_endcontent'
        )
        .addService(window.googletag.pubads());*/
    }

    setTargeting(targetingArray);
    window.googletag.pubads()
      .enableSingleRequest();
    window.googletag.pubads()
      .collapseEmptyDivs();
    window.googletag.pubads()
      .setCentering(true);
    window.googletag.pubads()
      .enableLazyLoad();
    clearTargeting(['subcategory', 'slug']);
    setOnRender();
    window.googletag.enableServices();

    definedSlots['category_page'] = [];
    slot1 && definedSlots['category_page'].push(slot1);
    slot2 && definedSlots['category_page'].push(slot2);
  });

  window.googletag.cmd.push(() => {
    window.googletag.display('pt_category_incontent1');
    window.googletag.display('pt_category_incontent2');
    /*window.googletag.display("pt_category_incontent3");
    window.googletag.display("pt_category_endcontent");*/
  });
};

export const videoCategoryPageAds = (targetingArray) => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'video_category_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'video_category_page';

  bottomAnchor();
  rightSide('pt_rightcol_desktop');
  oneByOneAdUnit();

  if (definedSlots['video_category_page']) {
    setTargeting(targetingArray);
    return addTimeout(refreshAds, 50, 'video_category_page');
  }

  let slot1;
  window.googletag.cmd.push(() => {
    if (isMobile) {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'pt_video_category_incontent1'
        )
        .addService(window.googletag.pubads());
    } else {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_video_category_incontent1'
        )
        .addService(window.googletag.pubads());
    }

    setTargeting(targetingArray);
    window.googletag.pubads()
      .enableSingleRequest();
    window.googletag.pubads()
      .collapseEmptyDivs();
    window.googletag.pubads()
      .setCentering(true);
    window.googletag.pubads()
      .enableLazyLoad();
    setOnRender();
    window.googletag.enableServices();

    definedSlots['video_category_page'] = [];
    slot1 && definedSlots['video_category_page'].push(slot1);

    window.googletag.display('pt_video_category_incontent1');
    window.googletag.display('pt_rightcol_desktop');
  });
};

export const subCategoryPageAds = (targetingArray) => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'subcategory_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'subcategory_page';

  bottomAnchor();
  oneByOneAdUnit();

  if (definedSlots['subcategory_page']) {
    setTargeting(targetingArray);
    return addTimeout(refreshAds, 50, 'subcategory_page');
  }

  let slot1;
  window.googletag.cmd.push(() => {
    if (isMobile) {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'pt_subcategory_incontent1'
        )
        .addService(window.googletag.pubads());
    } else {
      slot1 = window.googletag
        .defineSlot(
          '/22218985137/pensiontimes/pt_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'pt_subcategory_incontent1'
        )
        .addService(window.googletag.pubads());
    }

    setTargeting(targetingArray);
    window.googletag.pubads()
      .enableSingleRequest();
    window.googletag.pubads()
      .collapseEmptyDivs();
    window.googletag.pubads()
      .setCentering(true);
    window.googletag.pubads()
      .enableLazyLoad();
    clearTargeting(['slug']);
    setOnRender();
    window.googletag.enableServices();
    definedSlots['subcategory_page'] = [];
    definedSlots['subcategory_page'].push(slot1);

    window.googletag.display('pt_subcategory_incontent1');
    window.googletag.display('pt_rightcol_desktop');
  });
};
